



















































































import { Size } from '~/models/shared/types'
import { ButtonVariant, ButtonVariantType, Variant } from '~/models/app/variant'
import {
  defineComponent,
  computed,
  toRefs,
  PropType,
  ref,
  watch
} from '@nuxtjs/composition-api'
import CLink from '~/components/shared/configurable/link/CLink.vue'
import { useButtonClasses } from '~/compositions/button/classes'
import CDynamicIcon from '~/components/shared/configurable/icon/CDynamicIcon.vue'

export default defineComponent({
  components: { CDynamicIcon },
  props: {
    size: {
      type: String as PropType<Size>,
      default: Size.MEDIUM,
      required: false
    },
    variant: {
      type: String as PropType<ButtonVariantType>,
      default: undefined
    },
    outline: { type: Boolean, default: false },
    tertiary: { type: Boolean, default: false },
    circle: { type: Boolean, default: false },
    pill: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    pressed: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    tag: { type: String, default: 'button' },
    href: { type: String, default: null },
    to: { type: [Object, String], default: null },
    target: { type: String, default: '_self' },
    title: { type: String, default: null },
    loading: { type: Boolean, default: false },
    icon: { type: Object, default: () => null },
    iconPosition: {
      type: String as PropType<'left' | 'right'>,
      default: 'left',
      required: false
    },
    spinnerVariantProp: {
      type: String as PropType<ButtonVariantType>,
      default: undefined
    },
    floating: { type: Boolean, default: false },
    disableHover: { type: Boolean, default: false },
    containerClasses: { type: String, required: false, default: '' },
    slotClasses: { type: String, required: false, default: '' },
    attributeValue: { type: String, default: undefined },
    type: { type: String, default: 'button' }
  },
  setup(props, { emit }) {
    const {
      href,
      to,
      variant,
      pressed,
      size: sizeProp,
      disabled,
      tag,
      loading,
      spinnerVariantProp,
      floating,
      pill,
      outline,
      tertiary,
      circle,
      disableHover
    } = toRefs(props)
    const isLink = computed(() => Boolean(href.value || to.value))
    const size = ref(sizeProp.value)
    const {
      sizeClasses,
      variantClasses,
      linkClasses,
      positionClasses,
      roundnessClasses,
      spinnerClasses
    } = useButtonClasses(
      size,
      disabled,
      loading,
      variant,
      pressed,
      isLink,
      floating,
      pill,
      outline,
      tertiary,
      circle,
      disableHover
    )

    watch(sizeProp, () => {
      setSize(sizeProp.value)
    })
    const componentType = computed(() => {
      if (isLink.value) {
        return CLink
      }
      return tag.value
    })

    const role = computed(() => {
      if (isLink.value) {
        return 'button'
      }
      return null
    })
    const spinnerVariant = computed(() => {
      if (spinnerVariantProp.value) {
        return spinnerVariantProp.value
      }
      if (outline.value || tertiary.value) {
        return variant.value
      }
      if ([ButtonVariant.LIGHT].includes(variant.value)) {
        return Variant.LIGHT
      }

      return Variant.WHITE
    })

    const spinnerSize = computed(() => {
      switch (size.value) {
        case Size.MEDIUM:
        default:
          return { size: 'tw-w-[20px] tw-h-[20px]', border: 'tw-border-4' }
        case Size.EXTRA_SMALL:
          return { size: 'tw-w-[12px] tw-h-[12px]', border: 'tw-border-1' }
        case Size.SMALL:
          return { size: 'tw-w-[16px] tw-h-[16px]', border: 't2-border-2' }
        case Size.LARGE:
          return { size: 'tw-w-[24px] tw-h-[24px]', border: 'tw-border-4' }
      }
    })

    const iconSize = computed(() => {
      switch (size.value) {
        case Size.MEDIUM:
        default:
          return { class: 'tw-text-[20px]', number: 20 }
        case Size.EXTRA_SMALL:
          return { class: 'tw-text-[12px]', number: 12 }
        case Size.SMALL:
          return { class: 'tw-text-[16px]', number: 16 }
        case Size.LARGE:
          return { class: 'tw-text-[24px]', number: 24 }
      }
    })

    const setSize = (newSize: Size) => {
      size.value = newSize
    }
    const handleClick = (e: Event) => {
      if (!disabled.value) {
        emit('click', e)
      }
    }

    return {
      componentType,
      variantClasses,
      sizeClasses,
      linkClasses,
      role,
      isLink,
      spinnerSize,
      spinnerVariant,
      positionClasses,
      roundnessClasses,
      spinnerClasses,
      setSize,
      handleClick,
      iconSize
    }
  }
})
