





import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    direction: {
      type: String,
      default: 'vertical'
    }
  },
  setup(props) {
    const { direction } = toRefs(props)

    const classes = computed(() => {
      switch (direction.value) {
        case 'vertical':
        default: {
          return [
            'tw-flex-col',
            'tw-gap-y-2',
            '[&>.large:not(:first-child)]:tw-mt-1'
          ]
        }
        case 'list': {
          return ['tw-flex-col', 'tw-flex-1']
        }
        case 'horizontal': {
          return [
            'tw-flex-row',
            'tw-gap-x-4',
            '[&>.large:not(:first-child)]:tw-ml-1'
          ]
        }
      }
    })

    return { classes }
  }
})
